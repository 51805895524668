<!--  -->
<template>
  <div class="frame">
    <top
      top="1%"
      :isCount="true"
      :logoShow="false"
      @routerGo="$router.back()"
    ></top>
    <div class="content">
      <img src="../assets/about/about1.jpg" alt="关于远航" />
      <img src="../assets/about/about2.jpg" alt="关于远航" />
      <img src="../assets/about/about3.jpg" alt="关于远航" />
      <img src="../assets/about/about4.jpg" alt="关于远航" />
      <img src="../assets/about/about5.jpg" alt="关于远航" />
      <img src="../assets/about/about6.jpg" alt="关于远航" />
      <img src="../assets/about/about7.jpg" alt="关于远航" />
    </div>
  </div>
</template>

<script>
import top from "../components/top.vue";
export default {
  components: { top },
  data() {
    return {
      count: 8,
      timer: null,
    };
  },
  created() {
    localStorage.setItem("page_about", 1);
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.count == 0) {
        clearInterval(this.timer);
        return;
      }
      this.count--;
    }, 1000);
  },
  methods: {},
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang='scss' scoped>
.top {
  width: 100%;
  text-align: right;
  padding: 0 5%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 35px;

  .right {
    color: #fff;
  }

  img {
    width: auto;
    height: 20px;
  }
}
.content img {
  width: 100%;
  display: block;
}
</style>
