<template>
  <div class="layout">
    <img src="../assets/bg.jpg" class="bg" draggable="false" />
    <!-- 内容 -->
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}
.bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
</style>
