// baseUrl
// export const ip = "http://192.168.40.147:8082/"; //测试环境地址
export const ip = "https://h5.yuanhangqiche.com/api/"; //域名正式地址
export const codeURL = ip + "index/loginAdmin/getVerify"; //登录图形验证码
// 图片访问地址前缀
// export const URL = "http://192.168.40.147:8082/"; //测试环境
export const URL = "https://h5.yuanhangqiche.com/web_file/"; //正式环境
// 文件上传地址
export const uploadURL = ip + "web/file/upload"; // 常规
export const partUploadUrl = ip + "web/file/part"; // 分片上传地址
export const mergeUrl = ip + "web/file/merge"; // 分片合成地址
