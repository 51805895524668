<!--  -->
<template>
  <div class="frame">
    <top :isCount="true" @routerGo="$router.back()"></top>
    <div class="content">
      <div class="tabs">
        <div
          :class="{ tab: true, active: tabActive == 'h8' }"
          @click="setTab('h8')"
        >
          H8
        </div>
        <div
          :class="{ tab: true, active: tabActive == 'h9' }"
          @click="setTab('h9')"
        >
          H9
        </div>
        <div
          :class="{ tab: true, active: tabActive == 'y6' }"
          @click="setTab('y6')"
        >
          Y6
        </div>
        <div
          :class="{ tab: true, active: tabActive == 'y7' }"
          @click="setTab('y7')"
        >
          Y7
        </div>
      </div>
      <div id="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in swipeArr" :key="item">
            <img :src="item" class="slide" />
          </div>
        </div>
      </div>
      <div class="tips"></div>
    </div>
  </div>
</template>

<script>
const swipeTypes = {
  h8: [URL + "h8-1.png", URL + "h8-2.png", URL + "h8-3.png"],
  h9: [URL + "h9-1.png", URL + "h9-2.png", URL + "h9-3.png"],
  y6: [URL + "y6-1.png", URL + "y6-2.png", URL + "y6-3.png"],
  y7: [URL + "y7-1.png", URL + "y7-2.png", URL + "y7-3.png"],
};
import top from "../components/top.vue";
import { URL } from "../util/config";
export default {
  components: { top },
  data() {
    return {
      tabActive: "h8",
      swipe: null,
      swipeArr: [],
    };
  },
  created() {
    localStorage.setItem("page_type", 1);
  },
  mounted() {
    this.swipeArr = swipeTypes.h8;
    this.initSwipe();
  },
  methods: {
    setTab(type) {
      this.tabActive = type;
      this.swipeArr = swipeTypes[type];
      this.swipe.slideToLoop(0);
    },
    initSwipe() {
      this.swipe = new window.Swiper("#swiper", {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 90vw;
  margin: 0 auto;
  padding-top: 25%;

  .tabs {
    display: flex;
    margin-bottom: 17px;

    .tab {
      width: 100px;
      height: 16px;
      line-height: 16px;
      background: #ffe1ab;
      padding: 8px 0;
      color: #a17f4d;
      margin-right: 16px;
      text-align: center;
      border: 2px solid #ffe1ab;
    }
    .active {
      color: #fff;
      background-color: rgba($color: #ac956d, $alpha: 0.6);
      border-image: linear-gradient(-18deg, #a17f4d, #ffe1ab) 6 6;
    }
  }
  #swiper {
    width: 100%;
    overflow: hidden;
  }
  .slide {
    width: 100%;
    height: auto;
  }
}
</style>
