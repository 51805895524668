<!-- 首页 -->
<template>
  <div class="frame">
    <div class="content">
      <img src="../assets/home_logo.png" alt="" class="biglogo" />
      <img src="../assets/code3.jpg" alt="" class="server" />
      <!-- <img src="../assets/code2.png" alt="" class="public" /> -->
      <div class="tip">长按二维码识别关注</div>
      <div class="join" @click="$router.push('/welcome')">参与活动</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.content {
  .biglogo {
    width: 70%;
  }
  .server,
  .public {
    width: 35%;
  }
  img {
    margin: 8% auto 0;
    display: block;
  }
  .tip {
    color: #fff;
    font-size: 18px;
    text-align: center;
    // margin-top: 15px;
    margin-top: 100px;
  }
  .join {
    width: 40%;
    height: 44px;
    margin: 36px auto 0;
    text-align: center;
    color: #fff;
    background: linear-gradient(#a17f4d, #ffe1ab);
    line-height: 44px;

    &:active {
      transform: scale(0.9);
    }
  }
}
</style>
