<!-- 核销页面 -->
<template>
  <div class="frame">
    <top :rightShow="false"></top>
    <div class="content">
      <div class="title"><span>远航</span>汽车</div>
      <div class="sub">核销端</div>
      <div class="btn" @click="start">开始核销</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import top from "../components/top.vue";
export default {
  components: { top },
  data() {
    return {
      hasPower: true,
      appId: "wx9eaa64a768d1caa7",
    };
  },
  created() {
    if (!this.is_weixin()) return;
    this.initWeiXin();
  },
  mounted() {},
  methods: {
    is_weixin() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.match(/MicroMessenger/i) == "micromessenger";
    },
    async initWeiXin() {
      let loading = this.$toast.loading({
        message: "请稍等",
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      let that = this;
      let res = await this.$api.getSignParams({ path: "verification" });
      if (res.code == 0) {
        const { timestamp, noncestr, signature } = res.data;
        wx.config({
          debug: false,
          appId: this.appId,
          timestamp,
          nonceStr: noncestr,
          signature,
          jsApiList: ["scanQRCode"],
        });
        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: ["scanQRCode"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              loading.clear();
              if (!res.checkResult.scanQRCode) {
                that.$toast(res.checkResult.errMsg);
                that.hasPower = false;
              }
            },
          });
        });
        wx.error(function (res) {
          console.log(res);
          loading.clear();
          setTimeout(() => {
            that.$toast.fail("出现了意外");
          }, 500);
        });
      }
    },
    // 开启扫描
    start() {
      if (!this.hasPower) {
        this.$toast("调用扫一扫失败，请刷新");
        return;
      }
      wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode"],
        success: (res) => {
          var result = res.resultStr;
          // 处理扫描结果
          this.writeOff(result);
        },
      });
    },
    // 开始核销
    async writeOff(id) {
      let res = await this.$api.startWriteOff({ id });
      if (res.code != 0) {
        this.$dialog.alert({ title: "提示", message: res.msg });
        return;
      }
      this.$notify({ type: "primary", message: "核销成功!" });
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  padding-top: 20vh;
  text-align: center;
  color: #fff;

  .title {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    letter-spacing: 5px;

    span {
      color: #ffe1ab;
    }
  }
  .sub {
    font-size: 20px;
  }

  .btn {
    width: 50vw;
    padding: 15px 0;
    border-radius: 5px;
    background: linear-gradient(#a17f4d, #ffe1ab);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &:active {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }
}
</style>

